import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewsList from './components/NewsList';
import './App.css';

function App() {
  return (
    <div className="container">
      <NewsList />
    </div>
  );
}

export default App;
