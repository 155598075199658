import React from "react";

const NewsItem = ({ item }) => {
  const getTimeDifference = (date) => {
    const now = new Date();
    const diff = now - new Date(date);
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? "s" : ""}`;
    if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""}`;
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  };

  return (
    <div className="card mb-3">
      <div className="card-body">
        <img src={item.url_to_image} alt={item.title} className="news-image" />
        <div className="text-muted">
          <small>
            {item.source_name} - {getTimeDifference(item.published_at)}
          </small>
        </div>
        <h5 className="card-title">{item.title}</h5>
        <p className="card-text">{item.description}</p>
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          Read more
        </a>
      </div>
    </div>
  );
};

export default React.memo(NewsItem);
