import React, { useState, useEffect, useCallback, useRef } from "react";
import NewsItem from "./NewsItem";
import LanguageCountryPicker from "./LanguageCountryPicker";
import config from "../news-setting.json";
import _ from "lodash";

function NewsList() {
  const [news, setNews] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(() => localStorage.getItem("selectedLanguage") || "en");
  const [selectedCountry, setSelectedCountry] = useState(() => localStorage.getItem("selectedCountry") || "global");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMoreNews, setHasMoreNews] = useState(true); // State to track if more news is available
  const loader = useRef(null);

  const CACHE_EXPIRATION_MINUTES = 60; // 例如，设置缓存过期时间为60分钟

  const fetchNews = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const cacheKey = `news-${selectedLanguage}-${selectedCountry}-${page}`;
      let fetchedNews;

      const cachedData = localStorage.getItem(cacheKey);
      const cacheTime = localStorage.getItem(`${cacheKey}-time`);

      const isCacheExpired = () => {
        if (!cacheTime) return true;
        const now = new Date().getTime();
        const cachedTime = new Date(parseInt(cacheTime)).getTime();
        return now - cachedTime > CACHE_EXPIRATION_MINUTES * 60 * 1000;
      };

      if (cachedData && !isCacheExpired()) {
        fetchedNews = JSON.parse(cachedData);
      } else {
        const apiUrl = `${process.env.REACT_APP_API_URL}/?language=${selectedLanguage}&country=${selectedCountry}&page=${page}`;
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error("Network response was not ok.");
        const data = await response.json();
        fetchedNews = data.results;

        localStorage.setItem(cacheKey, JSON.stringify(fetchedNews));
        localStorage.setItem(`${cacheKey}-time`, new Date().getTime().toString());
      }

      const seenIds = new Set(news.map((item) => item.id));
      const validNews = fetchedNews.filter((newNewsItem) => {
        if (seenIds.has(newNewsItem.id)) {
          return false;
        }
        seenIds.add(newNewsItem.id);
        return true;
      });

      setNews((prevNews) => [...prevNews, ...validNews]);

      if (fetchedNews.length === 0) {
        setHasMoreNews(false); // Update when no more news items are available
      }
    } catch (error) {
      console.error("Fetch news error:", error);
      setError("Failed to load news");
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchNews = useCallback(_.debounce(fetchNews, 500), [selectedLanguage, selectedCountry, page, news]);

  useEffect(() => {
    debouncedFetchNews();
    return () => {
      debouncedFetchNews.cancel();
    };
  }, [debouncedFetchNews]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !isLoading && hasMoreNews) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [isLoading, hasMoreNews]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "600px",
      threshold: 0.2,
    });
    const currentLoader = loader.current;
    if (currentLoader) observer.observe(currentLoader);
    return () => {
      if (currentLoader) observer.unobserve(currentLoader);
    };
  }, [handleObserver]);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
    setPage(1);
    setNews([]);
    setSelectedCountry("global");
    localStorage.setItem("selectedCountry", "global");
    setHasMoreNews(true); // Reset hasMoreNews when language changes
  };

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    localStorage.setItem("selectedCountry", newCountry);
    setPage(1);
    setNews([]);
    setHasMoreNews(true); // Reset hasMoreNews when country changes
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="row">
      <div className="col-12">
        <LanguageCountryPicker
          selectedLanguage={selectedLanguage}
          selectedCountry={selectedCountry}
          onLanguageChange={handleLanguageChange}
          onCountryChange={handleCountryChange}
          config={config}
        />
      </div>
      {news.map((newsItem) => (
        <div key={newsItem.id} className="col-sm-12 col-md-6 col-lg-4">
          <NewsItem item={newsItem} />
        </div>
      ))}
      <div ref={loader}>{isLoading && <div>Loading more news...</div>}</div>
    </div>
  );
}

export default NewsList;
